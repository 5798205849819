import React, { useState } from 'react';
import { Copy } from 'lucide-react';

const PrepNotesGenerator = () => {
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [callNotes, setCallNotes] = useState('');
  const [generatedNotes, setGeneratedNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://67ac-18-219-192-24.ngrok-free.app/generate_notes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_name: companyName,
          company_url: website,
          raw_notes: callNotes,
        }),
      });
      const data = await response.json();
      setGeneratedNotes(data.notes);
    } catch (error) {
      console.error('Error generating notes:', error);
    }
    setIsLoading(false);
  };

  const handleCopy = () => {
    // Modify the generated notes to include an extra line break between sections
    const formattedNotes = generatedNotes.replace(/<\/p>/g, '</p><br>');
  
    // Create a new Blob with the formatted HTML content
    const blob = new Blob([formattedNotes], { type: 'text/html' });
  
    // Create a new ClipboardItem with the blob
    const clipboardItem = new ClipboardItem({ 'text/html': blob });
  
    // Use the Clipboard API to write the clipboard item
    navigator.clipboard.write([clipboardItem]).then(
      () => {
        console.log('Content copied to clipboard successfully!');
      },
      (err) => {
        console.error('Failed to copy content to clipboard:', err);
      }
    );
  };
  
  

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="w-full max-w-3xl bg-white shadow-lg p-6 rounded-md">
        <h1 className="text-3xl font-bold text-center text-blue-600 mb-6">Prep Notes Generator</h1>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          <textarea
            placeholder="Previous Call Notes (Optional)"
            value={callNotes}
            onChange={(e) => setCallNotes(e.target.value)}
            className="w-full p-2 border rounded-md h-32"
          />
          <button
            onClick={handleGenerate}
            disabled={isLoading}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md"
          >
            {isLoading ? 'Generating...' : 'Generate Prep Notes'}
          </button>
        </div>
        {generatedNotes && (
          <div className="mt-6">
            <h2 className="text-xl font-semibold mb-2">Generated Prep Notes:</h2>
            {/* Use dangerouslySetInnerHTML to render HTML content */}
            <div
              className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: generatedNotes }}
            />
          </div>
        )}
        {generatedNotes && (
          <button
            onClick={handleCopy}
            className="flex items-center bg-green-500 hover:bg-green-600 text-white py-2 px-4 mt-4 rounded-md"
          >
            <Copy className="mr-2 h-4 w-4" /> Copy Notes
          </button>
        )}
      </div>
    </div>
  );
};

export default PrepNotesGenerator;
